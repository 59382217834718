import React from 'react';
import './App.css';


function App() {
  return (
    <div className="App">
      <div className="Content">
        <div className="AppTitle">Transmittal</div>
        <div className="AppSubtitle">The smart podcast player</div>
        <div className="AppText">
          <p>The app is currently in closed beta.</p>
          <p>We hope to announce an open beta program in the coming months. Please check back later for further
            announcements</p>
        </div>
      </div>
      <div className="Footer">Transmittal - {new Date().getFullYear()}</div>
    </div>
  );
}

export default App;
